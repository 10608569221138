import { Grid, InputAdornment, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetch } from '@react-redux-fetch/hooks';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Field, { CheckBox } from '../../../../../../components/Form';
import WhenFieldChanges from '../../../../../../components/Form/WhenFieldChanges';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { Fund, TaxRate, TaxRateCode } from '../../../../../../config/api/types';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import { getDistributorsRequest } from '../../../../../fund/admin/api';
import { getDistributor } from '../../../../../organisation/admin/selectors';
import { getUser, getUserRole } from '../../../../../security/selectors';
import AvailabilityField from '../../containers/fields/AvailabilityField';
import DiscountCodesField from '../../containers/fields/DiscountCodesField';
import FundField from '../../containers/fields/FundField';
import TaxField from '../../containers/fields/TaxField';
import { useFieldPermissions } from '../../hooks/useFieldPermissions';
import Fieldset from '../Fieldset';
import FieldsetSubTitle from '../FieldsetSubTitle';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';
import CommercialField from './CommercialField';

type Props = {
  price?: number;
  priceDescription?: string;
  priceRegulatedActivated?: boolean;
  newTitleForm?: boolean;
  taxRate?: TaxRate;
  selectedProductFormTaxRateCode?: TaxRateCode;
  otherTaxRateAllowed?: boolean;
  showOrderTime?: boolean;
  selectedFund?: Fund;
};

const useStyles = makeStyles(() => ({
  helperText: {
    margin: '-12px 0px 12px',
    color: '#646464',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.03333em',
  },
}));

const Commercial = ({
  price,
  priceDescription,
  priceRegulatedActivated,
  taxRate,
  selectedProductFormTaxRateCode,
  otherTaxRateAllowed,
  newTitleForm,
  showOrderTime,
  selectedFund,
}: Props) => {
  const { t } = useTranslation();

  const uomPriceAdornment = {
    endAdornment: <InputAdornment position="end">€</InputAdornment>,
  };
  const uomPctAdornment = {
    endAdornment: <InputAdornment position="end">%</InputAdornment>,
  };
  const availabilitiesApiLink = useApiRoute('titleAvailabilities') || '';
  const taxRatesApiLink = useApiRoute('titleTaxRates') || '';
  const discountCodesApiLink = useApiRoute('titleDiscountCodes') || '';
  const fundsUrl = useApiRoute([
    'fundsDistributor',
    'fundsAdmin',
    'fundsBookstore',
    'fundsPublisher',
  ]);
  const classes = useStyles();
  const userRoles = useSelector(getUserRole);
  const isAdmin = userRoles && userRoles.includes('ROLE_ADMIN');

  /* Fetch distributor for comparing distributorId's. Fundscode may only be changed when the logged in user is the distributor of the title. */
  const [, fetchDistributor] = useFetch(getDistributorsRequest);
  const user = useSelector(getUser);
  const organisation = user?._embedded.organisation;
  const distributorLink = organisation?._links?.distributor?.href;

  useEffect(() => {
    if (distributorLink && fundsUrl) {
      fetchDistributor(distributorLink);
    }
  }, [distributorLink, fetchDistributor, fundsUrl]);

  const distributor = useSelector(getDistributor(distributorLink));

  const fieldPermissions = useFieldPermissions(
    distributor?.distributorId,
    selectedFund,
    Boolean(fundsUrl)
  );

  return (
    <SectionWrapper id="commercial">
      <FieldsetTitle>{t('title_section_commercial')}</FieldsetTitle>
      <Fieldset>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <AvailabilityField url={availabilitiesApiLink} required simpleList={newTitleForm} />
          </Grid>
          <Grid xs={3} item>
            {!newTitleForm && (
              <CommercialField
                component={Field}
                label={
                  <>
                    {t('title_supplyDate')}{' '}
                    <InfoTooltip
                      title={
                        fieldPermissions.selectedSupplyDate.isDisabled
                          ? t('only_distributor_may_edit_tooltip')
                          : t('title_supplyDate_tooltip')
                      }
                      inline
                    />
                  </>
                }
                type="date"
                name="selectedSupplyDate"
                disabled={fieldPermissions.selectedSupplyDate.isDisabled}
              />
            )}
          </Grid>
          {showOrderTime ? (
            <Grid xs={3} item>
              <CommercialField
                component={Field}
                label={
                  <>
                    {t('title_orderTime')}{' '}
                    <InfoTooltip
                      title={
                        fieldPermissions.orderTime.isDisabled
                          ? t('only_distributor_may_edit_tooltip')
                          : t('title_orderTime_tooltip')
                      }
                      inline
                    />
                  </>
                }
                type="text"
                name="orderTime"
                disabled={fieldPermissions.orderTime.isDisabled}
              />
            </Grid>
          ) : null}
        </Grid>
      </Fieldset>
      {fieldPermissions.funds.isVisible && (
        <Fieldset>
          <Grid container spacing={1}>
            <Grid xs={6} item>
              <FundField
                required
                preFetch={userRoles.includes('ROLE_DISTRIBUTOR')}
                onlyActive
                disabled={fieldPermissions.funds.isDisabled}
              />
            </Grid>
          </Grid>
        </Fieldset>
      )}
      <Fieldset>
        <FieldsetSubTitle>{t('title_heading_prices')}</FieldsetSubTitle>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <CommercialField
              component={Field}
              label={
                <>
                  {t('title_recommendedPrice')}
                  {' (*) '}
                  <InfoTooltip title={t('title_recommendedPrice_tooltip')} inline />
                </>
              }
              type="text"
              name="price"
              InputProps={uomPriceAdornment}
            />
          </Grid>
          <Grid xs={6} item>
            <CommercialField
              component={Field}
              label={t('title_priceDescription')}
              select
              type="select"
              name="priceDescription"
            >
              {!price && <MenuItem>&nbsp;</MenuItem>}
              <MenuItem value="priceIsFreeOfCharge">{t('title_free_of_charge')}</MenuItem>
              <MenuItem value="priceIsToBeAnnounced">{t('title_to_be_announced')}</MenuItem>
            </CommercialField>
          </Grid>
          <Grid xs={12} item>
            <Typography className={classes.helperText}>{t('title_price_requirement')}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <DiscountCodesField url={discountCodesApiLink} />
          </Grid>
          <Grid xs={3} item>
            <TaxField
              url={taxRatesApiLink}
              selectedProductFormTaxRateCode={selectedProductFormTaxRateCode}
              otherTaxRateAllowed={otherTaxRateAllowed}
            />
          </Grid>
          <Grid xs={3} item>
            {taxRate && taxRate.code === 'C' && (
              <CommercialField
                component={Field}
                label={t('title_percentageAtHighRate')}
                type="text"
                name="productForm.taxRate.percentageAtHighRate"
                InputProps={uomPctAdornment}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <CommercialField
              component={Field}
              label={
                <>
                  {t('title_priceAction_price')}{' '}
                  <InfoTooltip title={t('title_priceAction_price_tooltip')} inline />
                </>
              }
              type="text"
              name="priceAction.price"
              InputProps={uomPriceAdornment}
            />
          </Grid>
          <Grid xs={3} item>
            <CommercialField
              component={Field}
              label={t('title_price_from')}
              type="date"
              name="priceAction.startDate"
            />
          </Grid>
          <Grid xs={3} item>
            <CommercialField
              component={Field}
              label={t('title_price_to')}
              type="date"
              name="priceAction.endDate"
            />
          </Grid>
        </Grid>
      </Fieldset>

      {!newTitleForm && (
        <>
          <Fieldset>
            <FieldsetSubTitle>{t('title_heading_regulatedPrice')}</FieldsetSubTitle>
            <Grid container spacing={1}>
              <Grid xs={6} item>
                <CommercialField
                  component={Field}
                  label={
                    <>
                      {t('title_priceRegulated_price')}{' '}
                      <InfoTooltip title={t('title_priceRegulated_price_tooltip')} inline />
                    </>
                  }
                  type="text"
                  name="priceRegulated.price"
                  InputProps={uomPriceAdornment}
                  disabled={!isAdmin && priceRegulatedActivated}
                />
              </Grid>
              <Grid xs={3} item>
                <CommercialField
                  component={Field}
                  label={t('title_price_from')}
                  type="date"
                  name="priceRegulated.startDate"
                  disabled={!isAdmin && priceRegulatedActivated}
                />
              </Grid>
              <Grid xs={3} item>
                <CommercialField
                  component={Field}
                  label={t('title_price_to')}
                  type="date"
                  name="priceRegulated.endDate"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid xs={6} item>
                <CommercialField
                  component={Field}
                  label={
                    <>
                      {t('title_priceRegulatedAction_price')}
                      <InfoTooltip title={t('title_priceRegulatedAction_price_tooltip')} inline />
                    </>
                  }
                  type="text"
                  name="priceRegulatedAction.price"
                  InputProps={uomPriceAdornment}
                />
              </Grid>
              <Grid xs={3} item>
                <CommercialField
                  component={Field}
                  label={t('title_price_from')}
                  type="date"
                  name="priceRegulatedAction.startDate"
                />
              </Grid>
              <Grid xs={3} item>
                <CommercialField
                  component={Field}
                  label={t('title_price_to')}
                  type="date"
                  name="priceRegulatedAction.endDate"
                />
              </Grid>
            </Grid>
          </Fieldset>
          <Fieldset>
            <FieldsetSubTitle>{t('title_heading_restrictions')}</FieldsetSubTitle>
            <Grid container>
              <Grid xs={6} item>
                <CommercialField
                  component={CheckBox}
                  name="hasInformationRestriction"
                  label={
                    <>
                      {t('title_hasInformationRestriction')}{' '}
                      <InfoTooltip title={t('title_hasInformationRestriction_tooltip')} inline />
                    </>
                  }
                />
              </Grid>
              <Grid xs={6} item>
                <CommercialField
                  component={CheckBox}
                  name="hasOrderRestriction"
                  label={
                    <>
                      {t('title_hasOrderRestriction')}{' '}
                      <InfoTooltip title={t('title_hasOrderRestriction_tooltip')} inline />
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Fieldset>
        </>
      )}
      <WhenFieldChanges
        field="priceDescription"
        set="price"
        to={(value) => {
          return value ? '' : price || '';
        }}
      />
      <WhenFieldChanges
        field="price"
        set="priceDescription"
        to={(value) => {
          return value ? '' : priceDescription || '';
        }}
      />
    </SectionWrapper>
  );
};

export default Commercial;
