import { CircularProgress } from '@mui/material';
import React from 'react';
import { Organisation } from '../../../../../config/api/types';
import Fieldset from '../../components/Organisation/Fieldset';
import CbRelations from './CbRelations';
import Prefixes from './Prefixes';
import PublisherForm from './PublisherForm';

type Props = {
  organisation?: Organisation;
};

const Publisher = ({ organisation }: Props) => {
  if (!organisation) {
    return <CircularProgress />;
  }

  return (
    <>
      <Fieldset>
        <CbRelations organisation={organisation} />
      </Fieldset>
      <Fieldset>
        <Prefixes organisation={organisation} />
      </Fieldset>
      <PublisherForm organisation={organisation} />
    </>
  );
};

export default Publisher;
