import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarcodeSteppedFormProps } from '../types';
import DownloadBarcode from './DownloadBarcode';

const BarcodeGenerateStep = ({
  changeStep,
  submitting,
  values,
  submitSucceeded,
  user,
  ...props
}: BarcodeSteppedFormProps) => {
  const { t } = useTranslation();

  if (values.gtin13?.value == null || values.type?.value == null) return null;

  const barcodeDto = {
    gtin13: values.gtin13.value,
    type: values.type.value,
  };

  return (
    <Grid container spacing={3} direction="column">
      <Grid
        item
        xs={12}
        sx={{
          width: { md: '58.33%' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ mb: 2 }}>{t('barcode_download_intro')}</Typography>
        <Box sx={{ display: 'flex', gap: 4, mb: 2 }}>
          <div>
            <Typography sx={{ fontWeight: 500 }} component="span">
              {`${t('gtin13_barcode')}: `}
            </Typography>
            <Typography component="span">{barcodeDto.gtin13}</Typography>
          </div>
          <div>
            <Typography sx={{ fontWeight: 500 }} component="span">
              {`${t('form_format')}: `}
            </Typography>
            <Typography component="span">{values.type.label}</Typography>
          </div>
        </Box>
        <DownloadBarcode barcodeDto={barcodeDto} />
      </Grid>

      <Grid container sx={{ width: { md: '58.33%' } }} xs={12} item justifyContent="space-between">
        <Grid item>
          <Button onClick={() => changeStep(-1)} variant="outlined" disabled={submitting}>
            {t('form_previous')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BarcodeGenerateStep;
