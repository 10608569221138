import { Alert } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import Field from '../../../../components/Form/index';
import TextLink from '../../../../components/TextLink';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import { useDevice } from '../../../../helpers/useDevice';

type Props = {
  onSubmit: FormRenderProps['handleSubmit'];
  disabled?: boolean;
  error: string;
  errorCode: number;
  values: FormRenderProps['values'];
};

const StyledField = styled(Field)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: '0.75rem',
  },
}));

const Login = ({ onSubmit, disabled, error, errorCode, values }: Props) => {
  const {
    location: { state },
  } = useHistory<{ message?: string }>();
  const { t } = useTranslation();
  const forgotPWLink = usePath(ROUTE_KEY.FORGOT_PW);

  const { isDesktop } = useDevice();

  const errorMessage =
    errorCode === 401 ? (
      <Trans
        i18nKey="security_invalid"
        components={[
          <Link
            to={{
              pathname: forgotPWLink,
              state: {
                email: values.username,
              },
            }}
          />,
        ]}
      />
    ) : (
      error
    );

  return (
    <>
      {state?.message && (
        <Box mb={2}>
          <Alert severity="success">{state.message}</Alert>
        </Box>
      )}
      <form onSubmit={onSubmit}>
        <Box mb={1}>
          <StyledField
            name="username"
            type="text"
            label={t('security_code')}
            customError={errorMessage}
            disabled={disabled}
          />
        </Box>
        <Box>
          <StyledField
            name="password"
            type="password"
            label={t('security_pw')}
            disabled={disabled}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
            alignItems: {
              xs: 'flex-end',
              lg: 'center',
            },
            gap: 2,
            mt: {
              xs: 0,
              lg: 2,
            },
          }}
        >
          {forgotPWLink && (
            <Typography
              color="primary"
              sx={{
                textAlign: 'right',
                textDecoration: 'underline',
                lineHeight: '1.5rem',
                order: { lg: 1 },
              }}
            >
              <TextLink
                to={{
                  pathname: forgotPWLink,
                  state: {
                    email: values.username,
                  },
                }}
              >
                {t('security_pw_forgotten')}
              </TextLink>
            </Typography>
          )}

          <Button
            fullWidth={!isDesktop}
            type="submit"
            disabled={disabled}
            size="large"
            sx={{ padding: '13px 40px', order: { lg: 0 } }}
          >
            {t('security_login')}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Login;
