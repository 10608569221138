import { Button, Grid, MenuList, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { SubmissionErrors, ValidationErrors } from 'final-form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductForm } from '../../../config/api/types';
import flattenObjectKeys from '../../../helpers/flattenObjectKeys';
import Left from '../../title/admin/title/components/Left';
import SectionNavItem from '../../title/admin/title/components/SectionNavItem';
import Bibliographic from '../../title/admin/title/components/sections/Bibliographic';
import Commercial from '../../title/admin/title/components/sections/Commercial';
import Contributors from '../../title/admin/title/components/sections/Contributors';
import Education from '../../title/admin/title/components/sections/Education';
import NewTitleMediaFiles from '../../title/admin/title/components/sections/MediaFilesNotPossible';
import Promotion from '../../title/admin/title/components/sections/Promotion';
import Relations from '../../title/admin/title/components/sections/Relations';
import TargetAudience from '../../title/admin/title/components/sections/TargetAudience';
import Texts from '../../title/admin/title/components/sections/Texts';
import Thema from '../../title/admin/title/components/sections/Thema';
import TitleData from '../../title/admin/title/components/sections/TitleData';
import TitleFormat from '../../title/admin/title/components/sections/TitleFormat';
import { actualValidationErrors } from '../../title/admin/title/containers/TitleForm';
import {
  FormValues,
  SectionTitle,
  calculateSectionScore,
  sectionHasErrors,
} from '../../title/admin/title/domain';
import { IsbnFormValues } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  left: {
    height: '100%',
  },
  right: {
    overflowY: 'auto',
    height: `calc(100vh - ${theme.toolbar.height}px - 140px)`,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rightForm: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: '100%',
    overflowY: 'auto',
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
  rightWithActionButtons: {
    height: 'calc(100% - 50px)',
  },
  actionButtons: {
    paddingLeft: theme.spacing(3),
    // 17px for the vertical scrollbar
    paddingRight: `calc(${theme.spacing(3)} + 17px)`,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    padding: 6,
    textAlign: 'center',
    maxWidth: 215,
  },
}));

type Props = {
  values: Partial<IsbnFormValues>;
  submitErrors: SubmissionErrors;
  validationErrors: ValidationErrors;
  actionButtons?: React.ReactNode;
  change: (name: keyof FormValues, value?: string) => void;
  newTitleForm?: boolean;
  buttonsLeft?: React.ReactNode;
  isAuthenticated: boolean;
  touched?: Record<string, boolean>;
  onClickFetchFromExistingIsbn?: () => void;
};

const IsbnTitleForm = ({
  values,
  submitErrors,
  actionButtons,
  change,
  newTitleForm,
  buttonsLeft,
  isAuthenticated,
  validationErrors,
  touched,
  onClickFetchFromExistingIsbn,
}: Props) => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState<SectionTitle>(
    window.location.hash ? (window.location.hash.replace('#', '') as SectionTitle) : 'data'
  );
  const classes = useStyles();

  const flattenedErrors = submitErrors
    ? Object.keys(submitErrors).reduce((errors: typeof submitErrors, key) => {
        if (typeof submitErrors[key] === 'object') {
          const subKeys = Object.keys(submitErrors[key]);
          if (subKeys.length === 1) {
            return {
              ...errors,
              [key]: submitErrors[key][subKeys[0]],
            };
          }
        }

        return {
          ...errors,
          [key]: submitErrors[key],
        };
      }, {})
    : undefined;

  const errors = [
    ...(flattenedErrors ? flattenObjectKeys(flattenedErrors) : []),
    ...Object.keys(actualValidationErrors(validationErrors ?? {}, touched) || {}),
  ];

  return (
    <>
      <Left>
        <Grid
          container
          sx={{ flexDirection: 'column', justifyContent: 'space-between' }}
          className={classes.left}
        >
          <Grid item>
            {isAuthenticated && onClickFetchFromExistingIsbn !== undefined && (
              <Button type="button" onClick={onClickFetchFromExistingIsbn} sx={{ width: '100%' }}>
                {t('fetch_from_existing_gtin13')}
              </Button>
            )}
            <MenuList>
              <SectionNavItem
                section="data"
                score={calculateSectionScore('data', values as FormValues)}
                title={t('title_edit_section_data')}
                hasErrors={sectionHasErrors('data', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              <SectionNavItem
                section="format"
                score={calculateSectionScore('format', values as FormValues)}
                title={t('title_edit_section_form')}
                hasErrors={sectionHasErrors('format', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              <SectionNavItem
                section="bibliographic"
                score={calculateSectionScore('bibliographic', values as FormValues, newTitleForm)}
                title={t('title_edit_section_bib')}
                hasErrors={sectionHasErrors('bibliographic', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              <SectionNavItem
                section="contributors"
                score={calculateSectionScore('contributors', values)}
                title={t('title_edit_section_contributors')}
                hasErrors={sectionHasErrors('contributors', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              <SectionNavItem
                section="commercial"
                score={calculateSectionScore('commercial', values as FormValues)}
                title={t('title_edit_section_commercial')}
                hasErrors={sectionHasErrors('commercial', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              <SectionNavItem
                section="thema"
                score={calculateSectionScore('thema', values)}
                title={t('title_edit_section_thema')}
                hasErrors={sectionHasErrors('thema', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              <SectionNavItem
                section="mediaFiles"
                score={100}
                title={t('title_edit_section_mediaFiles')}
                hasErrors={sectionHasErrors('mediaFiles', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              <SectionNavItem
                section="targetAudience"
                score={calculateSectionScore('targetAudience', values as FormValues)}
                title={t('title_edit_section_targetAudience')}
                hasErrors={sectionHasErrors('targetAudience', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              {isAuthenticated && (
                <SectionNavItem
                  section="relations"
                  score={calculateSectionScore('relations', values as FormValues)}
                  title={t('title_edit_section_relations')}
                  hasErrors={sectionHasErrors('relations', errors)}
                  activeSection={activeSection}
                  onClick={setActiveSection}
                />
              )}
              <SectionNavItem
                section="promotion"
                score={calculateSectionScore('promotion', values)}
                title={t('title_edit_section_promotion')}
                hasErrors={sectionHasErrors('promotion', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              <SectionNavItem
                section="education"
                score={calculateSectionScore('education', values)}
                title={t('title_edit_section_education')}
                hasErrors={sectionHasErrors('education', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
              <SectionNavItem
                section="texts"
                score={calculateSectionScore('texts', values as FormValues)}
                title={t('title_edit_section_texts')}
                hasErrors={sectionHasErrors('texts', errors)}
                activeSection={activeSection}
                onClick={setActiveSection}
              />
            </MenuList>
            {errors.length > 0 && (
              <div className={classes.error}>{t('form_title_submit_error')}</div>
            )}
          </Grid>

          <Grid item>{buttonsLeft}</Grid>
        </Grid>
      </Left>

      <div className={classes.right}>
        <div
          className={clsx(classes.rightForm, actionButtons ? classes.rightWithActionButtons : '')}
        >
          <TitleData collectionName={values.collection?.title?.value} />
          <TitleFormat
            productFormFromServer={{} as ProductForm}
            selectedProductForm={values.selectedProductForm}
            selectedEBookType={values.selectedEBookType}
            onChangeFormValue={change}
            newTitleForm={newTitleForm}
          />
          <Bibliographic
            publisherName={values.publisher && values.publisher.organisation.name}
            newTitleForm={newTitleForm}
            hideGprcCheckbox
            hideGs1Checkbox
          />
          <Contributors formContributors={values.contributors || []} onChangeFormValue={change} />
          <Commercial
            price={values.price}
            priceDescription={values.priceDescription}
            taxRate={values.productForm && values.productForm.taxRate}
            selectedProductFormTaxRateCode={
              values.selectedProductForm &&
              values.selectedProductForm.taxRate &&
              values.selectedProductForm.taxRate.code
            }
            otherTaxRateAllowed={
              values.selectedProductForm && values.selectedProductForm.otherTaxRateAllowed
            }
            newTitleForm={newTitleForm}
          />
          <Thema
            selectedThemas={values.themas}
            selectedQualifiers={values.themaQualifiers}
            showKeywords={isAuthenticated}
            themaAndNurRequired
          />
          <NewTitleMediaFiles />
          <TargetAudience />
          {isAuthenticated && (
            <Relations
              initialReplacementOf={values.replacementOf}
              initialReplacedBy={values.replacedBy}
              selectedReplacementOf={values.selectedReplacementOf}
              selectedReplacedBy={values.selectedReplacedBy}
              selectedTitleInOtherLanguages={values.selectedTitleInOtherLanguages}
              canSelectTitlesInOtherLanguages={false}
              languages={values?.languages}
            />
          )}
          <Promotion
            formAwards={isAuthenticated ? values.awards || [] : undefined}
            onChangeFormValue={change}
          />
          <Education />
          <Texts
            flapCopy={values.flapCopy}
            promotionalHeadline={values.promotionalHeadline}
            annotation={values.annotation}
            onChangeFormValue={change}
          />
        </div>

        <div className={classes.actionButtons}>{actionButtons}</div>
      </div>
    </>
  );
};

export default IsbnTitleForm;
