import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LoginDTO } from '../../../config/api/types';
import { EXTERNAL_LINKS } from '../../../config/data/external_links';
import ExternalLink from '../../app/components/ExternalLink';
import { Actions } from '../actions';
import { loginRequest } from '../api';
import Login from '../components/homepage/Login';
import MobileDisclaimer from '../components/homepage/MobileDisclaimer';
import { getAuthResponseToken } from '../selectors';

const defaultFormValues: LoginDTO =
  process.env.NODE_ENV === 'development'
    ? {
        username: process.env.REACT_APP_USER || '',
        password: process.env.REACT_APP_PW || '',
      }
    : {
        username: '',
        password: '',
      };

const LoginForm = () => {
  const theme = useTheme();

  const [authFetch, authenticate] = useFetch(loginRequest);
  const authResponseToken = useSelector(getAuthResponseToken);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (authResponseToken) {
      dispatch(Actions.login(authResponseToken));
    }
  }, [authResponseToken, dispatch]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component="section">
      <Typography color="primary" sx={{ fontWeight: 500, fontSize: '1.5rem' }} component="h1">
        {t('login')}
      </Typography>

      <MobileDisclaimer />

      <Grid container spacing={5}>
        <Grid item xs={12} sm lg={12}>
          <Form
            onSubmit={(values: LoginDTO) => {
              authenticate(values);
            }}
            initialValues={defaultFormValues}
          >
            {({ handleSubmit, values }) => {
              return (
                <Login
                  onSubmit={handleSubmit}
                  disabled={authFetch?.pending}
                  error={authFetch?.rejected && authFetch.reason.message}
                  errorCode={authFetch?.rejected && authFetch.reason.cause.code}
                  values={values}
                />
              );
            }}
          </Form>
        </Grid>
        <Grid
          item
          xs={12}
          sm="auto"
          lg={12}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
        >
          <Box
            sx={{
              minWidth: '1px',
              minHeight: '1px',
              backgroundColor: theme.palette.primary.light,
              height: 'calc(100% - 1.5rem)',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm
          lg={12}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: { xs: -2, sm: 0, lg: -2 } }}
          >
            <Typography
              color="primary"
              sx={{ fontWeight: 500, fontSize: '1.125rem' }}
              component="h2"
            >
              {t('home_page_register_helper_title')}
            </Typography>
            <Typography component="p" variant="body1" sx={{ lineHeight: '1.5rem' }}>
              <Trans
                i18nKey="home_page_register_helper"
                components={[<ExternalLink href={EXTERNAL_LINKS['contact']} />]}
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
