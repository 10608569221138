import { Box, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '../../../../../components/Form';
import { Publisher } from '../../../../../config/api/types';
import PublisherGroupField from '../../components/Fields/PublisherGroupField';
import FormTitle from '../../components/FormTitle';

type Props = {
  publisher: Publisher;
};

const PublisherParams = ({ publisher }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <FormTitle title={t('organisation_edit_publisher_params_title')} />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CheckBox
            name="usesRegulatedPriceAlgorithm"
            label={t('organisation_edit_regulated_price_algorithm')}
          />
        </Grid>
        <Grid item>
          <CheckBox
            name="isPurchaseOrderRequired"
            label={t('organisation_edit_publisher_purchase_order_required')}
          />
        </Grid>
        <Grid item>
          <CheckBox
            name="isMemberGauGewu"
            label={t('organisation_edit_publisher_is_member_gau_gewu')}
          />
        </Grid>
        <Grid item>
          <CheckBox name="canChangeGprc" label={t('organisation_edit_publisher_can_change_gprc')} />
        </Grid>
        <Grid item>
          <CheckBox name="lannooGroup" label={t('organisation_edit_publisher_lannoogroup')} />
        </Grid>
        <Grid item>
          <CheckBox name="isDefaulter" label={t('organisation_edit_publisher_is_defaulter')} />
        </Grid>
        <Grid item>
          <CheckBox name="sendDataToCb" label={t('organisation_edit_publisher_send_data_to_cb')} />
        </Grid>
        <Grid item>
          <Tooltip
            title={t('organisation_edit_publisher_is_publishinggroup_tooltip')}
            arrow
            disableHoverListener={publisher.canDisablePublishingGroup}
          >
            <Box sx={{ display: 'inline-block' }}>
              <CheckBox
                name="isPublishingGroup"
                label={t('organisation_edit_publisher_is_publishinggroup')}
                disabled={!publisher.canDisablePublishingGroup}
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item>
          <Box sx={{ maxWidth: 400 }}>
            <PublisherGroupField initialValue={publisher.publishingGroupPublisherId} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PublisherParams;
