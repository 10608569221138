import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import BaseReactSelectField, {
  BaseReactSelectFieldProps,
} from '../../../../../../components/Form/BaseReactSelectField';
import { Availabilities, Availability } from '../../../../../../config/api/types';
import useFetch from '../../../../../../helpers/useFetch';
import { getAvailabilitiesRequest } from '../../api';
import CommercialField from '../../components/sections/CommercialField';

type Props = Partial<BaseReactSelectFieldProps> & {
  url: string;
  required?: boolean;
  simpleList?: boolean;
  isOnlyPublisher?: boolean;
};

const SIMPLE_LIST_EXCLUSIONS: string[] = ['01', '21', '22', '30', '31', '40', '47', '99'];

const AvailabilityField = ({ url, required, simpleList, label, ...other }: Props) => {
  const { change } = useForm();
  const { t } = useTranslation();
  const [availabilities, fetchAvailabilities] = useFetch<Availabilities>(
    getAvailabilitiesRequest(url)
  );

  useEffect(() => {
    if (!availabilities) {
      fetchAvailabilities();
    }
  }, [availabilities, fetchAvailabilities]);

  const getValues = (): Availabilities => {
    if (!availabilities?.value) {
      return [];
    }

    return simpleList
      ? availabilities.value.filter(
          (availability) => !SIMPLE_LIST_EXCLUSIONS.includes(availability.code)
        )
      : availabilities.value;
  };

  return (
    <>
      <CommercialField
        component={BaseReactSelectField}
        options={getValues()}
        getOptionValue={(option: Availability) => option.code}
        disabled={!availabilities || !availabilities.value}
        {...other}
        name="availability"
        label={<>{`${label || t('title_availability')} ${required ? '(*)' : ''}`}</>}
        parse={(value: Availability) => {
          if (!['10', '12', '31', '32'].includes(value.code)) {
            change('selectedSupplyDate', null);
          }
          return value;
        }}
      />
    </>
  );
};

export default AvailabilityField;
