import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDevice } from '../../../../helpers/useDevice';

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: '1rem',
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: '0.25rem',
}));

const MobileDisclaimer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isDesktop } = useDevice();

  if (isDesktop) return null;

  return (
    <StyledBox
      sx={{
        mb: {
          sm: 1,
        },
      }}
    >
      <Typography
        variant="body1"
        sx={{ color: theme.palette.secondary.dark, lineHeight: '1.5rem' }}
        component="p"
      >
        {t('mobile_disclaimer')}
      </Typography>
    </StyledBox>
  );
};

export default MobileDisclaimer;
