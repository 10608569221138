import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { EXTERNAL_LINKS } from '../../../config/data/external_links';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import { useDevice } from '../../../helpers/useDevice';
import ExternalLink from '../../app/components/ExternalLink';

const RotatedBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.veryLight,
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 0,
  left: 0,
  top: 0,
  transform: 'rotate(-1.7deg)',
}));

const RequestIsbn = () => {
  const { t } = useTranslation();

  const isbnApplicationPath = usePath(ROUTE_KEY.ISBN_APPLICATION, { isbn: '' });
  const { isDesktop } = useDevice();

  return (
    <Box
      component="section"
      sx={{
        px: {
          xs: 4,
          md: 7,
        },
        py: {
          xs: 5,
          md: 7,
        },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        margin: { xs: '0 -32px', md: '0 -40px', lg: 0 },
        alignItems: {
          sm: 'center',
          lg: 'flex-start',
        },
        zIndex: 9,
      }}
    >
      <RotatedBackground />
      <Box
        maxWidth={460}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: {
            xs: 3,
            lg: 4,
          },
          justifyContent: 'center',
          position: 'relative',
          zIndex: 9,
          flexGrow: 1,
        }}
      >
        <div>
          <Typography
            color="primary"
            sx={{
              fontWeight: 500,
              fontSize: {
                xs: '1.125rem',
                lg: '1.5rem',
              },
              mb: 1,
            }}
            component="h2"
          >
            {t('home_page_isbn_helper_title')}
          </Typography>
          <Typography component="p" sx={{ lineHeight: '1.5rem' }}>
            {t('home_page_isbn_helper')}
          </Typography>
        </div>
        <Button
          fullWidth={!isDesktop}
          variant="outlined"
          link={isbnApplicationPath}
          size="large"
          sx={{
            padding: '13px 40px',
            backgroundColor: '#fff',
            borderRadius: '0.25rem',
            alignSelf: 'flex-start',
          }}
        >
          {t('home_page_isbn_button')}
        </Button>
        <Typography component="p" sx={{ lineHeight: '1.5rem' }}>
          <Trans
            i18nKey="home_page_isbn_more_info"
            components={[<ExternalLink href={EXTERNAL_LINKS['isbn-info']} />]}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default RequestIsbn;
