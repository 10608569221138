import { Box, Container, Grid, List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { EXTERNAL_LINKS } from '../../../config/data/external_links';
import { useDevice } from '../../../helpers/useDevice';

const Image = styled('img')({
  display: 'block',
  width: '100%',
  maxWidth: '340px',
  margin: 'auto',
});

const Welcome = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDevice();

  const Intro = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'center', lg: 'flex-start' },
        gap: { xs: 2, lg: 3 },
      }}
    >
      <Typography
        component="h2"
        variant="h2"
        sx={{ fontWeight: 500, fontSize: '1.5rem', textAlign: { xs: 'center', lg: 'left' } }}
      >
        {t('home_page_pitch_title')}
      </Typography>
      <Typography
        component="p"
        variant="body1"
        sx={{ fontSize: '1.125rem', textAlign: { xs: 'center', lg: 'left' }, maxWidth: '850px' }}
        color="GrayText"
      >
        {t('home_page_pitch_subtitle')}
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        py: {
          xs: 7,
          md: 9,
        },
        backgroundColor: '#F9F9F9',
        mt: { xs: -2, lg: 7 },
        zIndex: 0,
        position: 'relative',
      }}
      component="section"
    >
      <Container
        sx={{
          px: {
            xs: 3,
            md: 4,
          },
          maxWidth: '1400px !important',
          overflow: 'hidden',
        }}
      >
        <Grid container spacing={5}>
          {!isDesktop ? (
            <Grid item xs={12}>
              {Intro}
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            md={5}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <Image src="/static/welcome.png" alt={t('home_page_pitch_title')} />
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 3, maxWidth: { md: '530px' } }}
            >
              {isDesktop ? Intro : null}
              <List sx={{ paddingLeft: '18px', listStyleType: 'disc', py: 0 }}>
                {[
                  'home_page_usp_1',
                  'home_page_usp_2',
                  'home_page_usp_3',
                  'home_page_usp_4',
                  'home_page_usp_5',
                ].map((text) => (
                  <ListItem key={text} sx={{ display: 'list-item', p: 0, lineHeight: '1.5rem' }}>
                    {t(text)}
                  </ListItem>
                ))}
              </List>
              <Typography
                component="p"
                variant="body1"
                sx={{ lineHeight: '1.5rem', textAlign: { xs: 'center', md: 'left' } }}
              >
                {t('home_page_more_info')}
              </Typography>
              <Button
                link={EXTERNAL_LINKS['home']}
                openInNewWindow
                size="large"
                sx={{
                  padding: '13px 40px',
                  color: '#fff',
                  alignSelf: { xs: 'center', md: 'flex-start' },
                }}
                color="secondary"
              >
                {t('home_page_more_info_button')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Welcome;
