import { CloudDownload } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import Button from '../../../components/Button';
import apiRoutes from '../../../config/api/routes';
import { ISBNBarcodeDTO } from '../../../config/api/types';
import useApiRoute from '../../../config/api/useApiRoute';
import { RootState } from '../../../config/store/types';
import alerts from '../../core/alerts/index';
import security from '../../security/index';

const useStyles = makeStyles((theme: Theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
  downloadBtn: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = DispatchProp & {
  barcodeDto: ISBNBarcodeDTO;
  jwt: string;
};

enum FETCH_STATE {
  IDLE,
  PENDING,
}

const DownloadBarcode = ({ barcodeDto, dispatch, jwt }: Props) => {
  const { t } = useTranslation();
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const classes = useStyles();

  const apiRoute = useApiRoute('barcodeGenerator');

  if (!apiRoute) {
    return null;
  }

  const handleClick = async () => {
    setFetchState(FETCH_STATE.PENDING);
    const res = await fetch(apiRoutes.create(apiRoute), {
      method: 'post',
      body: JSON.stringify({ ...barcodeDto, generate: true }),
      headers: {
        Authorization: `bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });

    if (res.status >= 400) {
      dispatch(alerts.actions.openError(res.statusText));
    } else {
      const blob = await res.blob();
      saveAs(blob, `${barcodeDto.gtin13}.${barcodeDto.type}`);
    }

    setFetchState(FETCH_STATE.IDLE);
  };

  return (
    <Button
      className={classes.downloadBtn}
      onClick={handleClick}
      disabled={fetchState === FETCH_STATE.PENDING}
    >
      <CloudDownload className={classes.leftIcon} />
      {t('barcode_download')}
    </Button>
  );
};

const mapState = (state: RootState) => ({
  jwt: security.selectors.getJwtToken(state),
});

export default connect(mapState)(DownloadBarcode);
