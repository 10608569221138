import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import BaseReactSelectField from '../../../components/Form/BaseReactSelectField';
import { BarcodeSteppedFormProps } from '../types';

const BarcodeFormatStep = ({ submitting, user, values, changeStep }: BarcodeSteppedFormProps) => {
  const { t } = useTranslation();

  const options = [
    { label: 'EPS', value: 'eps' },
    { label: 'TIFF', value: 'tiff' },
    { label: 'PNG', value: 'png' },
  ];

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12} sx={{ width: { md: '58.33%' } }}>
        <BaseReactSelectField
          label={t('form_format')}
          name="type"
          options={options}
          placeholder={t('form_format_placeholder')}
        />
      </Grid>

      <Grid container item sx={{ width: { md: '58.33%' } }} justifyContent="space-between">
        <Grid item>
          <Button onClick={() => changeStep(-1)} variant="outlined" disabled={submitting}>
            {t('form_previous')}
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" disabled={submitting}>
            {t('form_next')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BarcodeFormatStep;
