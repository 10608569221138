import { Grid } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import pick from 'lodash/pick';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import PageTitle from '../../../components/PageTitle';
import Stepper from '../../../components/Stepper';
import { ISBNBarcodeDTO } from '../../../config/api/types';
import useApiRoute from '../../../config/api/useApiRoute';
import { useAsyncValidation } from '../../../helpers/finalFormAsyncValidation';
import { getUser } from '../../security/selectors';
import { createBarcodeRequest } from '../api';
import BarcodeFormatStep from '../containers/BarcodeFormatStep';
import BarcodeGenerateStep from '../containers/BarcodeGenerateStep';
import Gtin13Step from '../containers/Gtin13Step';
import { BarcodeFormValues, IsbnBarcodeFormStep } from '../types';

const initialValues: BarcodeFormValues = {
  gtin13: undefined,
  type: undefined,
};

const ISBNBarcodePage = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [generateBarcodeRequest, generateBarcode] = useFetch(createBarcodeRequest);
  const { createSubmissionPromise } = useAsyncValidation(generateBarcodeRequest);
  const apiRoute = useApiRoute('barcodeGenerator') || '';
  const user = useSelector(getUser);

  const steps: IsbnBarcodeFormStep[] = [
    {
      name: t('form_barcode_step_gtin13'),
      component: Gtin13Step,
      fields: ['gtin13'],
    },
    {
      name: t('form_barcode_step_format'),
      component: BarcodeFormatStep,
      fields: ['type'],
    },
    {
      name: t('form_barcode_step_generate'),
      component: BarcodeGenerateStep,
    },
  ];

  const currentStep = steps[activeStep];
  const Component = currentStep.component;

  const getDataToSubmit = (dto: Partial<ISBNBarcodeDTO>) => {
    if (currentStep.fields?.length) {
      const partialDto = {} as Partial<ISBNBarcodeDTO>;
      currentStep.fields.forEach((field) => {
        partialDto[field] = dto[field] || (null as any);
      });
      return partialDto;
    }
    return dto;
  };

  const handleIsbnSubmit = (values: BarcodeFormValues) => {
    const valuesToSubmit: Partial<ISBNBarcodeDTO> = {
      gtin13: values.gtin13?.value,
      type: values.type?.value,
    };
    const dataToSubmit = getDataToSubmit(valuesToSubmit);
    generateBarcode(apiRoute, dataToSubmit);

    return createSubmissionPromise().then((result) => {
      if (result) {
        const resultDto = result as Partial<ISBNBarcodeDTO>;
        const stepErrors = pick(resultDto, currentStep.fields || []);

        if (Object.keys(stepErrors).length) {
          console.log(stepErrors);
          return stepErrors;
        }

        if (activeStep < steps.length - 1) {
          setActiveStep(activeStep + 1);
        }
      }
    });
  };

  if (!user) {
    return null;
  }

  return (
    <ConstrainedPageContent center={false}>
      <Grid container direction="column">
        <Grid item xs={12} sx={{ width: { md: '58.33%' } }}>
          <PageTitle>{t('isbn_barcode')}</PageTitle>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ width: { md: '58.33%' }, marginTop: '12px', marginBottom: '20px' }}
        >
          <Stepper
            steps={steps.map((step) => ({ label: step.name }))}
            alternativeLabel
            activeStep={activeStep}
            onSelectStep={setActiveStep}
          />
        </Grid>
      </Grid>
      <Form
        initialValues={initialValues}
        onSubmit={handleIsbnSubmit}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitSucceeded, ...props }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Component
                user={user}
                changeStep={(value) => setActiveStep(activeStep + value)}
                submitSucceeded={Boolean(submitSucceeded && generateBarcodeRequest?.fulfilled)}
                {...props}
                submitting={Boolean(generateBarcodeRequest?.pending)}
              />
            </form>
          );
        }}
      />
    </ConstrainedPageContent>
  );
};

export default ISBNBarcodePage;
