import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/Button';
import { Organisation, PublisherDTO } from '../../../../../config/api/types';
import { useAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import { getLink } from '../../../../../helpers/hateoas';
import { getPublisherRequest, updatePublisherRequest } from '../../../../title/admin/title/api';
import Fieldset from '../../components/Organisation/Fieldset';
import { getPublisher } from '../../selectors';
import Gpsr from './Gpsr';
import PublisherParams from './PublisherParams';

type Props = {
  organisation: Organisation;
};

type FormValues = PublisherDTO;

const PublisherForm = ({ organisation }: Props) => {
  const { t } = useTranslation();
  const publisherLink = getLink(organisation, 'publisher') || '';
  const [, fetchPublisher] = useFetch(getPublisherRequest);
  const [editPublisherRequest, updatePublisher] = useFetch(updatePublisherRequest);
  const publisher = useSelector(getPublisher);

  const { createSubmissionPromise } = useAsyncValidation(editPublisherRequest);

  useEffect(() => {
    if (publisherLink) {
      fetchPublisher(publisherLink);
    }
  }, [publisherLink, fetchPublisher]);

  const handlePublisherUpdate = (values: PublisherDTO) => {
    updatePublisher(publisherLink, {
      ...values,
      name: values.name === '' ? undefined : values.name,
      contactName: values.contactName === '' ? undefined : values.contactName,
    });
  };

  if (!publisher) {
    return null;
  }

  return (
    <Form
      initialValues={publisher}
      keepDirtyOnReinitialize
      onSubmit={(values: FormValues) => {
        handlePublisherUpdate(values);
        return createSubmissionPromise();
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Fieldset>
            <PublisherParams publisher={publisher} />
          </Fieldset>
          <Fieldset>
            <Gpsr />
          </Fieldset>

          <Button type="submit" disabled={editPublisherRequest?.pending}>
            {t('form_action_save')}
          </Button>
        </form>
      )}
    />
  );
};

export default PublisherForm;
