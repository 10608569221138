import { Box, Grid, Toolbar, useTheme } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import { EXTERNAL_LINKS } from '../../../../config/data/external_links';
import EnvLabel from '../../../app/components/EnvLabel';
import ExternalLink from '../../../app/components/ExternalLink';
import Logo from '../../../app/components/Nav/Logo';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: 0,
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1, 0),
  },
}));

const AppBar = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledToolbar>
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        {process.env.REACT_APP_ENVIRONMENT !== 'prod' && (
          <Grid
            item
            xs={12}
            sm
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
              order: {
                sm: 1, // EnvLabel in the middle on desktop
              },
            }}
          >
            <EnvLabel />
          </Grid>
        )}
        <Grid
          item
          xs
          sx={{
            order: {
              xs: 1, // EnvLabel on top for mobile
              sm: 0, // EnvLabel in the middle on desktop
            },
          }}
        >
          <Box style={{ maxWidth: 240 }}>
            <Logo />
          </Box>
        </Grid>
        <Grid
          item
          xs
          sx={{
            order: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexShrink: 0,
              alignItems: 'flex-end',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                rowGap: 1,
                columnGap: 2,
                [theme.breakpoints.up('sm')]: {
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end',
                },
              }}
            >
              <ExternalLink href={EXTERNAL_LINKS['about']}>{t('home_page_about')}</ExternalLink>
              <Button variant="contained" link={EXTERNAL_LINKS['contact']} openInNewWindow>
                {t('nav_help_contact')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledToolbar>
  );
};

export default AppBar;
