import { Box, Theme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import cxs from 'classnames';
import not from 'ramda/es/not';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { NestedClientRoute } from '../../../../config/routes/types';
import Logo from './Logo';
import NavItem from './NavItem';
// import { useSelector } from 'react-redux';
// import { getNotifications } from '../../../notifications/selectors';
import EnvLabel from '../EnvLabel';

const styles = (theme: Theme) => {
  const drawerWidth = theme.drawer.width;

  return createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.background.navigation,
    },
    drawerOpen: {
      width: drawerWidth,
      [theme.breakpoints.down('lg')]: {
        width: 200,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    list: {
      overflow: 'hidden',
    },
    envLabel: {
      margin: theme.spacing(3),
    },
  });
};

type Props = WithStyles<any> & {
  match: RouteComponentProps['match'];
  clientRoutes: NestedClientRoute[];
};

const Nav = ({ classes, match, clientRoutes }: Props) => {
  const isOpen = true;
  // const notifications = useSelector(getNotifications);
  // const numNotifications = notifications?.total;

  const enhanceRoute = (route: NestedClientRoute) => {
    // switch (route.badge) {
    //   case 'newOrders':
    //     const enhancedRoute: NestedClientRoute = {
    //       ...route,
    //       badgeValue: numNotifications,
    //     };
    //     return enhancedRoute;
    // }
    return route;
  };

  const classNames = cxs(classes.drawer, {
    [classes.drawerOpen]: isOpen,
    [classes.drawerClose]: not(isOpen),
  });

  return (
    <Drawer
      variant="permanent"
      className={classNames}
      classes={{ paper: classNames }}
      open={isOpen}
    >
      <EnvLabel className={classes.envLabel} />
      <Box sx={{ padding: '0 1.5rem' }}>
        <Logo />
      </Box>

      <List className={classes.list}>
        {clientRoutes.map(
          (route) =>
            !route.hideInNav && (
              <NavItem key={route.path} match={match} route={enhanceRoute(route)} />
            )
        )}
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(Nav);
