import {
  ErrorPromiseState,
  PromiseState as FetchPromiseState,
  SuccessPromiseState,
} from '@react-redux-fetch/core';
import i18next from 'i18next';
import memoize from 'lodash/memoize';
import set from 'lodash/set';
import snakeCase from 'lodash/snakeCase';
import { useEffect, useRef } from 'react';
import { PromiseState } from 'react-redux-fetch';

type FetchName = string;

export type PropertyPathMapper = Record<string, string>;
export type PromiseResolver = (value?: {} | PromiseLike<{}> | undefined) => void;

type Error = {
  property_path: string;
  message: string;
};

function transformToFinalFormErrors(propertyPathMapper?: PropertyPathMapper) {
  return (fields: Record<string, string>, error: Error) => {
    if (!error.property_path) {
      return fields;
    }
    const tmpFieldNames: string[] = error.property_path.split('|');
    const fieldNames = propertyPathMapper
      ? tmpFieldNames.map((fieldName) => {
          const found = Object.keys(propertyPathMapper).find((regex) =>
            new RegExp(regex).test(fieldName)
          );
          return found ? propertyPathMapper[found] : fieldName;
        })
      : tmpFieldNames;

    return fieldNames.reduce((agg, fieldName) => {
      return set(
        agg,
        fieldName.split('.'),
        i18next.t(error.message, {
          field: i18next.t(`form_${snakeCase(fieldName)}`).toLowerCase(),
          defaultValue: error.message,
        })
      );
    }, fields);
  };
}

export const validatePromiseState = (
  promiseState: PromiseState<any>,
  propertyPathMapper?: PropertyPathMapper
) => {
  if (
    promiseState.meta &&
    promiseState.meta.status >= 400 &&
    promiseState.meta.status < 500 &&
    promiseState.reason &&
    Array.isArray(promiseState.reason.cause)
  ) {
    return promiseState.reason.cause.reduce(transformToFinalFormErrors(propertyPathMapper), {});
  }

  return false;
};

/**
 * @deprecated
 * Async validation for deprecated useFetch
 * */
export const useDeprecatedAsyncValidation = (
  promiseState?: PromiseState<any>,
  propertyPathMapper?: PropertyPathMapper
) => {
  const validationRef = useRef<null | {
    resolve: PromiseResolver;
    reject: PromiseResolver;
  }>(null);

  useEffect(() => {
    if (validationRef.current && promiseState && !promiseState.pending) {
      validationRef.current.resolve(validatePromiseState(promiseState, propertyPathMapper));
      validationRef.current = null;
    }
  }, [promiseState, propertyPathMapper]);

  return {
    createSubmissionPromise: () => {
      return new Promise((resolve, reject) => {
        validationRef.current = {
          resolve,
          reject,
        };
      });
    },
  };
};

function isValidationArrayError(promiseState: ErrorPromiseState) {
  return (
    Array.isArray(promiseState.reason.cause) &&
    promiseState.reason.cause.length > 0 &&
    promiseState.reason.cause[0].property_path
  );
}

function isValidationError(promiseState: ErrorPromiseState) {
  return !Array.isArray(promiseState.reason.cause) && promiseState.reason.cause.message != null;
}

function validatePromiseStateForReduxFetch(
  promiseState: SuccessPromiseState | ErrorPromiseState,
  propertyPathMapper?: PropertyPathMapper
) {
  if (promiseState.rejected) {
    if (isValidationArrayError(promiseState)) {
      return promiseState.reason.cause.reduce(transformToFinalFormErrors(propertyPathMapper), {});
    }
    if (isValidationError(promiseState)) {
      //Only used for password reset when unknown email. property_path is not returned here.
      return {
        email: promiseState.reason.cause.message,
      };
    }
  }

  return false;
}

export const useAsyncValidation = (
  promiseState?: FetchPromiseState,
  propertyPathMapper?: PropertyPathMapper
) => {
  const validationRef = useRef<null | {
    resolve: PromiseResolver;
    reject: PromiseResolver;
  }>(null);

  useEffect(() => {
    if (validationRef.current && promiseState && !promiseState.pending) {
      validationRef.current.resolve(
        validatePromiseStateForReduxFetch(promiseState, propertyPathMapper)
      );
      validationRef.current = null;
    }
  }, [promiseState, propertyPathMapper]);

  return {
    createSubmissionPromise: () => {
      return new Promise((resolve, reject) => {
        validationRef.current = {
          resolve,
          reject,
        };
      });
    },
  };
};

const createFinalFormAsyncValidation = (fetchName: FetchName) => {
  let promise: null | {
    resolve: PromiseResolver;
    reject: PromiseResolver;
  } = null;

  const createSubmissionPromise = () => {
    return new Promise((resolve, reject) => {
      promise = {
        resolve,
        reject,
      };
    });
  };

  const handleFulfil = (resourceName: FetchName) => {
    if (!promise) {
      // throw newNoPromiseError(fetchName);
      return;
    }

    if (resourceName === fetchName) {
      promise.resolve();
      promise = null;
    }
  };

  const handleReject = (resourceName: FetchName, promiseState: PromiseState<any>) => {
    if (!promise) {
      // throw newNoPromiseError(fetchName);
      return;
    }

    if (resourceName !== fetchName) {
      return;
    }

    const validationErrors = validatePromiseState(promiseState);
    promise.resolve(validationErrors);

    promise = null;
  };

  return {
    createSubmissionPromise,
    handleFulfil,
    handleReject,
  };
};

export default memoize(createFinalFormAsyncValidation);
