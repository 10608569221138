import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../components/Form';
import FormTitle from '../../components/FormTitle';

const Gpsr = () => {
  const { t } = useTranslation();
  return (
    <div>
      <FormTitle title={t('organisation_edit_publisher_gpsr_title')} />
      <Box sx={{ maxWidth: 400 }}>
        <div>
          <Field
            label={t('organisation_edit_publisher_gpsr_organisation_name')}
            type="text"
            name="name"
          />
        </div>
        <div>
          <Field
            label={t('organisation_edit_publisher_gpsr_contact_name')}
            type="text"
            name="contactName"
          />
        </div>
        <div>
          <Field
            label={t('organisation_edit_publisher_gpsr_contact_tel')}
            type="text"
            name="phoneNumber"
          />
        </div>
        <div>
          <Field
            label={t('organisation_edit_publisher_gpsr_contact_email')}
            type="text"
            name="email"
          />
        </div>
      </Box>
    </div>
  );
};

export default Gpsr;
