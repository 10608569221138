import { Box, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EXTERNAL_LINKS } from '../../../config/data/external_links';
import ExternalLink from '../../app/components/ExternalLink';

const Image = styled('img')({
  display: 'block',
  width: '110px',
});

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: 3,
        py: 4,
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          gap: 3,
        }}
      >
        <ExternalLink href={EXTERNAL_LINKS['terms-of-service']}>
          {t('home_page_terms_of_service')}
        </ExternalLink>
        <ExternalLink href={EXTERNAL_LINKS['privacy-declaration']}>
          {t('home_page_privacy_declaration')}
        </ExternalLink>
      </Box>
      <ExternalLink href={EXTERNAL_LINKS['m4b-home']}>
        <Image src="/static/meta4books-logo.png" alt="Meta4Books" />
      </ExternalLink>
    </Box>
  );
};

export default Footer;
