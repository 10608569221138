import { Box, Container, Grid } from '@mui/material';
import React from 'react';

// App imports:
import AppBar from '../components/homepage/AppBar';
import Footer from '../containers/Footer';
import LoginForm from '../containers/LoginForm';
import RequestIsbn from '../containers/RequestIsbn';
import Welcome from '../containers/Welcome';

const HomePage = () => {
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Container
        sx={{
          px: {
            xs: 3,
            md: 4,
          },
          pb: {
            lg: 2,
          },
          maxWidth: '1400px !important',
        }}
      >
        <AppBar />
        <Grid
          container
          spacing={{
            xs: 5,
            lg: 6,
          }}
        >
          <Grid item lg={6}>
            <LoginForm />
          </Grid>
          <Grid item xs lg={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <RequestIsbn />
          </Grid>
        </Grid>
      </Container>
      <Welcome />
      <Container
        sx={{
          px: {
            xs: 3,
            md: 4,
          },
          maxWidth: '1400px !important',
          overflow: 'hidden',
        }}
      >
        <Footer />
      </Container>
    </Box>
  );
};

export default HomePage;
