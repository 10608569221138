import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import title from '../../title/admin/title';
import { BarcodeSteppedFormProps } from '../types';

const Gtin13Step = ({
  user,
  changeStep,
  submitting,
  submitErrors,
  values,
  dirtyFieldsSinceLastSubmit,
  ...props
}: BarcodeSteppedFormProps) => {
  const { t } = useTranslation();

  const cancelLink = usePath(user ? ROUTE_KEY.ISBN : ROUTE_KEY.LOGIN);

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12} sx={{ width: { md: '58.33%' } }}>
        <title.components.SearchTitleField label={t('gtin13_barcode')} name="gtin13" />
      </Grid>

      <Grid
        container
        xs={12}
        sx={{ width: { md: '58.33%' }, justifyContent: 'space-between' }}
        item
      >
        <Grid item>
          <Button link={cancelLink} variant="outlined" disabled={submitting}>
            {t('form_confirm_disagree')}
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" disabled={submitting}>
            {t('form_next')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Gtin13Step;
