import { FetchConfig } from '@react-redux-fetch/core';
import i18next from 'i18next';
import apiRoutes from '../../config/api/routes';
import {
  IsbnApplication,
  ISBNBarcodeDTO,
  IsbnDTO,
  MasterPrefixDTO,
  Prefix,
  PrefixApplication,
  PrefixApplicationDto,
  ReserveIsbnDto,
} from '../../config/api/types';
import { Data } from '../../config/store/types';

export const getIsbnApplicationsRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      isbnApplications: (prev, next) => next,
    },
  };
};

export const getIsbnApplicationRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      isbnApplication: (prev, next) => next,
    },
  };
};

export const createIsbnRequest = (url: string, body: IsbnDTO): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    requestKey: 'createIsbnApplication',
    fetchOptions: {
      body,
    },
    repository: {},
  };
};

export const updateIsbnApplicationRequest = (
  url: string,
  body: IsbnApplication
): FetchConfig<Data> => {
  return {
    method: 'PUT',
    url: apiRoutes.create(url),
    fetchOptions: {
      body,
      successMsg: i18next.t('form_isbn_update_success'),
    },
    repository: {
      isbnApplication: (prev, next) => next,
    },
  };
};

export const getPrefixesRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    optimistic: {
      prefixes: () => null,
    },
    repository: {
      prefixes: (prev, next) => next,
    },
  };
};

export const getPrefixRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      prefix: (prev, next) => next,
    },
  };
};

export const createPrefixRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    repository: {
      prefix: (prev, next) => next,
      prefixes: (prev, next) => ({
        ...prev,
        total: prev.total + 1,
        _embedded: {
          items: [...prev._embedded.items, next],
        },
      }),
    },
  };
};

export const updatePrefixRequest = (url: string, body: Partial<Prefix>): FetchConfig<Data> => {
  return {
    method: 'PUT',
    fetchOptions: {
      body,
      successMsg: i18next.t('form_prefix_update_success'),
    },
    url: apiRoutes.create(url),
    repository: {
      prefix: (prev, next) => next,
    },
  };
};

export const getMasterPrefixesRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      masterPrefixes: (prev, next) => next,
    },
  };
};

export const createMasterPrefixRequest = (
  url: string,
  body: MasterPrefixDTO
): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    fetchOptions: {
      body,
    },
    repository: {
      masterPrefixes: (prev, next) => {
        prev._embedded.items = [next, ...prev._embedded.items];
        return prev;
      },
    },
  };
};

export const getBillingTypesRequest = (): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.billingTypes(),
    repository: {
      billingTypes: (prev, next) => next,
    },
  };
};

export const reserveIsbnNumbersRequest = (url: string, body: ReserveIsbnDto): FetchConfig<Data> => {
  return {
    method: 'post',
    url: apiRoutes.create(url),
    fetchOptions: {
      body,
      successMsg: i18next.t('prefix_reserve_numbers_success', { count: +body.amount }),
    },
    repository: {
      reservedIsbnNumbers: (prev, next) => next,
    },
  };
};

export const createPrefixApplicationRequest = (
  url: string,
  body: PrefixApplicationDto
): FetchConfig<Data> => ({
  method: 'POST',
  url: apiRoutes.create(url),
  requestKey: 'prefixApplication',
  fetchOptions: {
    body,
  },
  repository: {},
});

export const createBarcodeRequest = (
  url: string,
  body: Partial<ISBNBarcodeDTO>
): FetchConfig<Data> => ({
  method: 'POST',
  url: apiRoutes.create(url),
  requestKey: 'isbnBarcode',
  fetchOptions: {
    body,
  },
  repository: {},
});

export const getPrefixApplicationsRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      prefixApplications: (prev, next) => next,
    },
  };
};

export const getPrefixApplicationRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      prefixApplication: (prev, next) => next,
    },
  };
};

export const updatePrefixApplicationRequest = (
  url: string,
  body: PrefixApplication
): FetchConfig<Data> => {
  return {
    method: 'PUT',
    url: apiRoutes.create(url),
    fetchOptions: {
      body,
      successMsg: i18next.t('form_isbn_update_success'),
    },
    repository: {
      prefixApplication: (prev, next) => next,
    },
  };
};
