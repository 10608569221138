import { useSelector } from 'react-redux';
import { Fund } from '../../../../../config/api/types';
import { getUserRole } from '../../../../security/selectors';

type FieldPermission = {
  isVisible?: boolean;
  isDisabled?: boolean;
};

type FieldPermissions = {
  funds: FieldPermission;
  selectedSupplyDate: FieldPermission;
  orderTime: FieldPermission;
};

export const useFieldPermissions = (
  distributorId?: string,
  selectedFund?: Fund,
  hasFundsUrl?: boolean
): FieldPermissions => {
  const userRoles = useSelector(getUserRole);
  const isOnlyPublisher =
    userRoles && userRoles.includes('ROLE_PUBLISHER') && !userRoles.includes('ROLE_DISTRIBUTOR');

  const isOwnDistribution = distributorId === selectedFund?.distributor?.distributorId;

  const isFundAvailable = selectedFund?.isAvailable;

  /* If we are a distributor, make sure we are also the title distributor OR the current fundscode is available. Otherwise, we are allowed to edit funds if we are not a distributor but we'll always need the fundsUrl */
  const canEditFund =
    ((userRoles.includes('ROLE_DISTRIBUTOR') && (isOwnDistribution || isFundAvailable)) ||
      !userRoles.includes('ROLE_DISTRIBUTOR')) &&
    hasFundsUrl;

  return {
    funds: { isVisible: canEditFund, isDisabled: isOnlyPublisher },
    selectedSupplyDate: { isVisible: true, isDisabled: isOnlyPublisher && !isFundAvailable },
    orderTime: { isVisible: true, isDisabled: isOnlyPublisher },
  };
};
